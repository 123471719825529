import React from "react"

const Fusion = props => {
  return (
    <div className="fusionWrap">
      <div className="conceptTitle">
        <h2 className="conceptHeader">{props.concept}</h2>
      </div>
      <div className="fusionContainer">
        <div className="fusion">
          <h2 className="music">{props.title}</h2>
          <div className="triangleWrap">
            <div className="circle fusion-one">
              <h2 className="pointName">{props.one}</h2>
            </div>
            <div className="circle fusion-two">
              <h2 className="pointName">{props.two}</h2>
            </div>
            <div className="circle fusion-three">
              <h2 className="pointName">{props.three}</h2>
            </div>
            <div className="triangle"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Fusion
