import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import Button from "./Button"
import styled from "styled-components"

const Hero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "spotlight.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.image.childImageSharp.fluid

      return (
        <BackgroundImage className={className} fluid={image}>
          <div className="sloganHero hero">
            <div className="sloganInner">
              {" "}
              <h1>For The Love Of Music</h1>
              <p>
                For
                <span className="adult">adult beginners</span>
                who want to learn how to progress, perform and produce music.
              </p>
              <Link to="/contact">
                <Button name="BOOK IN" />
              </Link>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const BackgroundImageStyle = styled(Hero)`
  background-position: 60%;
`

export default BackgroundImageStyle
