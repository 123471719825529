import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/styles.scss"

import Img from "gatsby-image"
import Hero from "../components/Hero"
import Covid from "../components/Covid"

import Fusion from "../components/Fusion"
import Seo from "../components/Seo"

import Button from "../components/Button"
import { Link } from "gatsby"

const Index = () => {
  const image = useStaticQuery(graphql`
    query {
      band: file(relativePath: { eq: "BGtwo.jpg" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hat: file(relativePath: { eq: "DanH.jpg" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tech: file(relativePath: { eq: "techniqueG.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      create: file(relativePath: { eq: "createG.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      perform: file(relativePath: { eq: "performG.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      theory: file(relativePath: { eq: "theoryG.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      theband: file(relativePath: { eq: "band.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vocals: file(relativePath: { eq: "vinyl.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <Covid />

      <div className="unique">
        <div className="paraOne">
          <div className="words">
            <h2>Welcome To The Band Academy</h2>
            <p>
              The Band Academy is about taking a unique approach to learning and
              performing music. We specialise in Guitar, Bass and
              Singer-Songwriting. I don't know who said it but I love the quote
              "Music is the sound of emotion". Learning music is about
              developing enough skill, enough artistry enough physical and
              technical freedom to allow yourself to express yourself.
            </p>
            <p>
              If we start here with this expansive and exciting pursuit of
              musical expression then who knows how good you could be and how
              much enjoyment you could have learning and playing music with The
              Band Academy.
            </p>
          </div>

          <div className="imgWrap">
            <Img
              width="340"
              height="340"
              className="image"
              fixed={image.band.childImageSharp.fixed}
            />
          </div>
        </div>
        <div className="paraTwo">
          <div className="imgWrap">
            <Img
              width="340"
              height="340"
              className="image"
              fixed={image.hat.childImageSharp.fixed}
            />
          </div>
          <div className="words">
            <h2>So What Makes Us Different?</h2>
            <p>
              I have been a teacher, instructor, and coach in various subjects
              and disciplines including music for twenty years and this
              experience has provided me with a unique approach and set of tools
              for helping my students advance.
            </p>
            <p>
              The Band Academy can help you develop the technical ability to
              progress on your instrument and the theoretical knowledge to
              understand musical composition, we can also help you develop your
              creativity and confidence in performing.
            </p>
          </div>
        </div>
      </div>

      <div className="learn-container">
        <div className="learn-wrap-what">
          <h2>What We Teach</h2>

          <p>
            You can learn many things with us, from music production to
            performing live, sight-reading and so on but the journey to
            discovering it all starts with one of our fundamental pathways.
          </p>
        </div>
        <div className="learn-wrap">
          <div className="learn">
            <h2>Guitar</h2>
            <p>
              One of the aspects of Guitar that makes it such a beautiful
              instrument is the amount of ways you can play a note or a chord.
              Bends, vibrato, rakes, slides and trills all combine to give you
              the opportunity to create a unique sound.
            </p>
            <h3>Guitar Lesson One</h3>
            <p>
              Hold down the second fret of the fifth string &#40; also know as
              the A string &#41; with your index finger. Then strum downward on
              the top two strings the E and A strings. Play around with how hard
              and fast you strike the strings and see if you can create
              different feels from just this one simple exercise.
            </p>
          </div>
          <div className="learn">
            <h2>Bass Guitar</h2>
            <p>
              The bass is the backbone of any great band. The bassist is usually
              the underappreciated groove maestro that stands in the shadows
              just out of the casual observer's sight. But the bassist needs to
              hone a range of skills if they hope to make a band rock, groove
              and swing.
            </p>
            <h3>Bass Lesson One</h3>
            <p>
              Start on fourth string &#40; also known as the E string &#41; the
              fattest string on the bass and choose a 12 bar blues song like The
              Thrill Is Gone by BB King. Listen to the song and then play along
              and see if you find 3 notes that work with it. Quick Tip It's in
              the key of B so start on the 7th fret of the fourth string.
            </p>
          </div>
          <div className="learn">
            <h2>Vocals</h2>
            <p>
              I encourage everyone to sing because I believe it enhances one's
              ability as a musician greatly. I also encourage everyone to learn
              an instrument for precisely the same reason. That said, we can go
              into depth on ways to improve your vocal sounds from the get-go.
            </p>
            <h3>Vocal Lesson One</h3>
            <p>
              Listen carefully to your favourite artist and begin to mimic. Just
              take it a line at a time and be sure to listen more than you sing.
              Try to get a sense of their phrasing and how they are generating
              their vocal sound. Our ears can tell us so much and we can train
              them to hear more of the music that has always been there.
            </p>
          </div>
          <div className="learn">
            <h2>Songwriting</h2>
            <p>
              Songwriting is an incredibly fulfilling creative endeavour, and
              everyone does it differently. I teach songwriting for various
              reasons; one to encourage creativity, two as a way to understand
              music theory and chord progressions and three as another way of
              encouraging out the original music in you.
            </p>
            <h3>Songwriting Lesson One</h3>
            <p>
              John Lennon said "I'm an artist, and if you give me a tuba, I'll
              bring you something out of it. " So lesson one is no matter how
              little you know about your given instrument try to bring something
              out of it. Experiment and play until you have a melody of a few
              notes. Don't worry about lyrics or the quality of the tune. Just
              get the creative juices flowing.
            </p>
          </div>
        </div>
      </div>

      <div className="when">
        <h2>When?</h2>
        <p>One To One's are at flexible times</p>
        <p>Class Every Monday Starts at 18:30pm</p>
        <p>Class Every Tuesday Starts at 18:30pm</p>

        <p>Adult Beginners Welcome</p>

        <Link to="/prices">
          <Button name="SEE PRICES" />
        </Link>
      </div>

      <div className="theAcademyWrap">
        <div className="theAcademy">
          <div className="topics tech">
            <div className="imgIconWrap">
              <Img
                width="50"
                height="50"
                fixed={image.tech.childImageSharp.fixed}
              />
            </div>
            <h3>Chops</h3>
            <p>
              Develop your physical technique and dexterity with exercises and
              practice schedules designed for you to improve in a focused and
              efficient manner. Technique for technique's sake is not what we
              are about, we keep things musically focused.
            </p>
          </div>
          <div className="topics create">
            <div className="imgIconWrap">
              <Img
                width="50"
                height="50"
                fixed={image.create.childImageSharp.fixed}
              />
            </div>
            <h3>Creativity</h3>
            <p>
              Creativity is about running with an idea that has a buzz. It's
              about creating momentum and allowing inspiration to flow. Learn
              how creativity is exciting, invigorating, life-enriching as well
              as seeing how it can produce great musical ideas.
            </p>
          </div>
          <div className="topics perform">
            <div className="imgIconWrap">
              <Img
                width="50"
                height="50"
                fixed={image.perform.childImageSharp.fixed}
              />
            </div>
            <h3>Performance</h3>
            <p>
              Performance for many is a real threshold. Learn how to become more
              comfortable in this area by taking small steps outside of the
              comfort zone until finally, you can fully express yourself no
              matter the audience.
            </p>
          </div>
          <div className="topics perform">
            <div className="imgIconWrap">
              <Img
                width="50"
                height="50"
                fixed={image.theband.childImageSharp.fixed}
              />
            </div>
            <h3>Band</h3>
            <p>
              Experience what its like to be in a band and enjoy the positive
              social engagement that The Band Academy is all about. You will get
              the chance to put together songs and create sets for performing.
            </p>
          </div>
          <div className="topics theory">
            <div className="imgIconWrap">
              <Img
                width="50"
                height="50"
                fixed={image.theory.childImageSharp.fixed}
              />
            </div>
            <h3>Music Theory</h3>
            <p>
              Gain a practical understanding of music theory in a way that frees
              up your creativity and musical instinct. Music theory is not
              boring it is a fantastic subject that will massively improve your
              playing and writing.
            </p>
          </div>
          <div className="topics theory">
            <div className="imgIconWrap">
              <Img
                width="50"
                height="50"
                fixed={image.vocals.childImageSharp.fixed}
              />
            </div>
            <h3>Record</h3>
            <p>
              At every stage, you should be recording yourself playing and
              singing music. A recording is a fantastic feedback tool to get a
              gauge for how you are sounding. It also lays the foundation for
              getting involved in producing singles and albums in the future.
            </p>
          </div>
        </div>
      </div>

      <div className="beginnersWrap">
        <div className="beginnersContent">
          <div className="beginnersWord">
            <h2>Beginners Welcome</h2>
            <p>
              The Band Academy is designed for adult beginners in guitar, bass,
              vocals and songwriting. We are creating a culture which is about
              helping each other get better and welcoming new people. Its not
              about how good you are its about growing as a musician. We are all
              in the same boat!
            </p>
            <p>
              It's all about creating a thriving community of friendly musicians
              that are continually improving in all areas of music, simply "for
              the love of music".
            </p>
          </div>
        </div>
      </div>
      <Fusion
        concept="The Three Elements of Music"
        one="Rhythm"
        two="Harmony"
        three="Melody"
        title="Music"
      />
    </Layout>
  )
}

export default Index
