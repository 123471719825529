import React from "react"

const Covid = () => {
  return (
    <div className="covid">
      <h1>Covid Compliant</h1>

      <p>
        Due to Covid all sessions are now taking place via Zoom. So it's easier
        than ever to get involved.
      </p>
    </div>
  )
}

export default Covid
